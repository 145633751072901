// feature
import styled, { css } from 'styled-components'
import { connect } from 'react-redux'
import { mapStateToProps, mapDispatchToProps } from '@/store/index'
import { breakpointDown, breakpointUp } from '@/utils/breakpoints'
import { metaData } from '@/feature/staticData'
import { colorConfig, mixins, zIndex } from '@/utils/styleConfig'
import { Seo, LayoutContainer } from '@/utils/globalComponents'

// components
import Layout from '@/components/Layout'
import { ExampleCard, ExampleCardList } from '@/components/ExampleCard'
import { HeadingLevel1 } from '@/components/HeadingLevel1'
import { HeadingLevel2 } from '@/components/HeadingLevel2'
import { LinkButton } from '@/components/LinkButton'
import { ResponsiveImage } from '@/components/ResponsiveImage'
import { RoundedCornersContainer } from '@/components/RoundedCornersContainer'
import { TopServiceCard } from '@/components/TopServiceCard'
import { BreakPointHiddenBr, TextLink, TextThemeColor } from '@/components/Utils'

type TabId = 'newGraduates' | 'midCareer'

interface TabProps {
  id: TabId
  active: boolean
  onClick: (event) => void
}

const IndexPage = props => {
  const handleClick = (event): void => {
    props.setRecruitControlTabs(event)
  }

  const isNewGraduates = props.currentTab === 'newGraduates'
  const isMidCareer = props.currentTab === 'midCareer'

  return (
    <Layout marginBottom={0}>
      <Seo page="recruit" />
      <Recruit>
        <LayoutContainer>
          <HeadingLevel1 note="recruit">採用情報</HeadingLevel1>
          <Lead>
            <HeadingLevel2>
              <TextThemeColor>
                企画からメンテナンスまで
                <BreakPointHiddenBr direction="down" breakpoint="sm" />
                人を楽しませるものづくり
              </TextThemeColor>
            </HeadingLevel2>
            <LeadText>
              130年以上の歴史の中で培ってきた機械と電気、制御、
              <BreakPointHiddenBr direction="down" breakpoint="sm" />
              情報処理の独自技術を応用し、お客様のニーズに合った
              <BreakPointHiddenBr direction="down" breakpoint="sm" />
              オーダーメイド製品の開発から設計、製作までの一貫体制を築いてきました。
              <br />
              そのため、設計者は図面作成だけでなく、顧客打ち合わせや設計、
              <BreakPointHiddenBr direction="down" breakpoint="sm" />
              製品の稼働テストにも携わります。専門知識・スキルに加えて
              <BreakPointHiddenBr direction="down" breakpoint="sm" />
              コミュニケーション力、責任を持って最後までやり遂げる根気が求められますが、
              <BreakPointHiddenBr direction="down" breakpoint="sm" />
              自分のアイデアをものづくりに活かせる醍醐味を実感できます。
            </LeadText>
          </Lead>

          <div id="recruit-anchor" />

          <Tabs>
            <Tab
              type="button"
              id="newGraduates"
              active={isNewGraduates}
              onClick={event => {
                handleClick(event)
              }}
            >
              新卒採用
            </Tab>
            <Tab
              type="button"
              id="midCareer"
              active={isMidCareer}
              onClick={event => {
                handleClick(event)
              }}
            >
              中途採用
            </Tab>
          </Tabs>
        </LayoutContainer>

        {isNewGraduates && (
          <>
            <LayoutContainer>
              <About>
                <HeadingLevel2 note="about">Ai-Rのこと</HeadingLevel2>
                <AboutList>
                  <li>
                    <TopServiceCard
                      heading="会社概要"
                      link={metaData.company.url}
                      imgSrc="/images/top/company.jpg"
                    >
                      お客様の『あったらいいな』にお応えし
                      <BreakPointHiddenBr direction="up" breakpoint="md" />
                      世の中の『楽』に貢献する
                    </TopServiceCard>
                  </li>
                  <li>
                    <TopServiceCard
                      heading="情報機器事業部"
                      link={metaData.it.url}
                      imgSrc="/images/top/it.jpg"
                    >
                      独自の技術とノウハウを生かして
                      <BreakPointHiddenBr direction="up" breakpoint="md" />
                      オフィスの省力化を実現します。
                    </TopServiceCard>
                  </li>
                  <li>
                    <TopServiceCard
                      heading="ディスプレイ事業部"
                      link={metaData.display.url}
                      imgSrc="/images/top/display.jpg"
                    >
                      企画からメンテナンスまで
                      <BreakPointHiddenBr direction="up" breakpoint="md" />
                      一気通貫でお応えします。
                    </TopServiceCard>
                  </li>
                </AboutList>
              </About>
            </LayoutContainer>
            <LayoutContainer bgColor="attention">
              <Number>
                <HeadingLevel2 note="number">数字で見るAi-R</HeadingLevel2>
                <NumberList>
                  <li>
                    <RoundedCornersContainer>
                      <NumberCard>
                        <NumberHeading>平均勤続年数</NumberHeading>
                        <NumberAchievement>
                          14.5<small>年</small>
                        </NumberAchievement>
                      </NumberCard>
                    </RoundedCornersContainer>
                  </li>
                  <li>
                    <RoundedCornersContainer>
                      <NumberCard>
                        <NumberHeading>月平均所定外労働時間</NumberHeading>
                        <NumberAchievement>
                          8.2<small>時間</small>
                        </NumberAchievement>
                      </NumberCard>
                    </RoundedCornersContainer>
                  </li>
                  <li>
                    <RoundedCornersContainer>
                      <NumberCard>
                        <NumberHeading>平均有給休暇取得日数</NumberHeading>
                        <NumberAchievement>
                          12.2<small>日</small>
                        </NumberAchievement>
                      </NumberCard>
                    </RoundedCornersContainer>
                  </li>
                </NumberList>
                <NumberNote>※2024年度</NumberNote>
              </Number>
            </LayoutContainer>
            <LayoutContainer>
              <Flow>
                <HeadingLevel2 note="schedule">採用スケジュール</HeadingLevel2>
                <FlowOrderedList>
                  <li>
                    <FlowIcon>
                      <img src="/images/recruit/icon_entry.svg" alt="" width="128" height="128" />
                    </FlowIcon>
                    <FlowLabel>エントリー</FlowLabel>
                  </li>
                  <li>
                    <FlowIcon>
                      <img
                        src="/images/recruit/icon_information-session.svg"
                        alt=""
                        width="128"
                        height="128"
                      />
                    </FlowIcon>
                    <FlowLabel>
                      会社説明会
                      <br />
                      （対面/WEB）
                    </FlowLabel>
                  </li>
                  <li>
                    <FlowIcon>
                      <img
                        src="/images/recruit/icon_interview.svg"
                        alt=""
                        width="128"
                        height="128"
                      />
                    </FlowIcon>
                    <FlowLabel>
                      面接
                      <br />
                      （個別/グループ）
                    </FlowLabel>
                  </li>
                  <li>
                    <FlowIcon>
                      <img src="/images/recruit/icon_check.svg" alt="" width="128" height="128" />
                    </FlowIcon>
                    <FlowLabel>適性検査</FlowLabel>
                  </li>
                  <li>
                    <FlowIcon>
                      <img
                        src="/images/recruit/icon_interview.svg"
                        alt=""
                        width="128"
                        height="128"
                      />
                    </FlowIcon>
                    <FlowLabel>面接</FlowLabel>
                  </li>
                  <li>
                    <FlowIcon>
                      <img
                        src="/images/recruit/icon_unofficial-decision.svg"
                        alt=""
                        width="128"
                        height="128"
                      />
                    </FlowIcon>
                    <FlowLabel>内々定</FlowLabel>
                  </li>
                </FlowOrderedList>
              </Flow>
            </LayoutContainer>
            <LayoutContainer bgColor="attention">
              <LinkGroup>
                <BannerList>
                  <li>
                    <a
                      href="https://job.mynavi.jp/26/pc/search/corp264330/outline.html"
                      target="_blank"
                      rel="noopener"
                    >
                      <img
                        src="/images/recruit/bnr_mynavi_2026.png"
                        alt="マイナビ 2026"
                        width="498"
                        height="124"
                      />
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://job.rikunabi.com/2026/company/r716691070/"
                      target="_blank"
                      rel="noopener"
                    >
                      <img
                        src="/images/recruit/bnr_rikunabi_2026.png"
                        alt="リクナビ 2026"
                        width="498"
                        height="124"
                      />
                    </a>
                  </li>
                </BannerList>
                <TextLinkContainer>
                  <TextLink
                    label="社長×学生インタビュー（2020年）"
                    link="https://www.youtube.com/watch?v=peT5myNM3HI"
                    target="_blank"
                  />
                  <TextLink
                    label="採用担当インタビュー（2022年）"
                    link="https://www.youtube.com/watch?v=EpUK7f8acSM"
                    target="_blank"
                  />
                  <TextLink
                    label="会社説明（2022年）ショートver."
                    link="https://jobtv.jp/company/detail/CP11-7271-6327-09"
                    target="_blank"
                  />
                </TextLinkContainer>
              </LinkGroup>
            </LayoutContainer>
          </>
        )}

        {isMidCareer && (
          <>
            <LayoutContainer>
              <Example>
                <RoundedCornersContainer>
                  <ResponsiveImage src="/images/recruit/img_recruit_01_sm.jpg" alt="" />
                </RoundedCornersContainer>
                <ExampleHeading>
                  <HeadingLevel2>
                    全国の科学館やテーマパーク等の展示装置や
                    <BreakPointHiddenBr direction="down" breakpoint="sm" />
                    業務の省力化を実現する処理装置など実績も豊富
                  </HeadingLevel2>
                </ExampleHeading>
                <ExampleCardList>
                  <li>
                    <ExampleCard
                      caseType="museum"
                      heading="エデュケーション模型"
                      subHeading="モノの原理や仕組みが一目でわかる"
                    ></ExampleCard>
                  </li>
                  <li>
                    <ExampleCard
                      caseType="shoppingComplex"
                      heading="モニュメント"
                      subHeading="ショッピングモールやコンコースの"
                    ></ExampleCard>
                  </li>
                  <li>
                    <ExampleCard
                      caseType="museum"
                      heading="不思議な展示装置"
                      subHeading="自然界の法則や現象のメカニズム"
                    ></ExampleCard>
                  </li>
                  <li>
                    <ExampleCard
                      caseType="event"
                      heading="展示装置"
                      subHeading="プレゼンテーションをサポートする"
                    ></ExampleCard>
                  </li>
                  <li>
                    <ExampleCard
                      caseType="museum"
                      heading="実験装置"
                      subHeading="強大なエネルギーが体感できる"
                    ></ExampleCard>
                  </li>
                  <li>
                    <ExampleCard
                      caseType="themePark"
                      heading="参加体験型ゲーム"
                      subHeading="メカトロニクスを駆使した"
                    ></ExampleCard>
                  </li>
                  <li>
                    <ExampleCard
                      caseType="original"
                      heading="疑似体験できる装置"
                      subHeading="社会の仕組みや防災への取り組みを"
                    ></ExampleCard>
                  </li>
                  <li>
                    <ExampleCard
                      caseType="themePark"
                      heading="ゲーム型体力測定機"
                      subHeading="自分の限界にチャレンジする"
                    ></ExampleCard>
                  </li>
                  <li>
                    <ExampleCard
                      caseType="shoppingComplex"
                      heading="ムービング・オブジェ"
                      subHeading="賑わいと憩いの空間を演出する"
                    ></ExampleCard>
                  </li>
                  <li>
                    <ExampleCard
                      caseType="office"
                      heading="検査装置"
                      subHeading="郵送物の個人情報を保護する"
                    ></ExampleCard>
                  </li>
                  <li>
                    <ExampleCard
                      caseType="office"
                      heading="丁合システム"
                      subHeading="通信販売業務をサポートする"
                    ></ExampleCard>
                  </li>
                  <li>
                    <ExampleCard
                      caseType="office"
                      heading="アプリケーション開発"
                      subHeading="データ照合と装置を連動させる"
                    ></ExampleCard>
                  </li>
                </ExampleCardList>
              </Example>
            </LayoutContainer>
            <LayoutContainer bgColor="attention">
              <LinkButtonContainer>
                <LinkButton
                  label="エントリーする"
                  link="https://en-gage.net/ai-r_saiyo/"
                  color="theme"
                  target="_blank"
                />
              </LinkButtonContainer>
            </LayoutContainer>
          </>
        )}
      </Recruit>
    </Layout>
  )
}

const Recruit = styled.div`
  position: relative;

  ${breakpointUp('md')} {
    &::after {
      content: '';
      display: block;
      position: absolute;
      z-index: ${zIndex.background};
      top: -7rem;
      left: 0;
      right: 0;
      transform: translateX(38rem);
      margin: auto;
      width: 64rem;
      height: 64rem;
      background: top / 100% no-repeat url(/images/recruit/mv_img.png);
    }
  }
`

const Lead = styled.section`
  margin-top: 8rem;

  ${breakpointUp('md')} {
    margin-top: 6rem;
  }
`

const LeadText = styled.p`
  margin-top: 1.6rem;
  line-height: 1.8;
  letter-spacing: 0.04em;

  ${breakpointDown('sm')} {
    font-size: 1.4rem;
  }

  ${breakpointUp('md')} {
  }
`

const Tabs = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 2.5rem;
  margin-top: 8rem;

  ${breakpointUp('md')} {
    margin-top: 13rem;
  }
`

const Tab = styled.button<TabProps>`
  ${mixins.hoverButton()}
  display: inline-flex;
  justify-content: center;
  align-items: center;
  position: relative;
  padding: 1rem;
  border-radius: 9999px;
  line-height: 1.5;
  font-weight: bold;
  letter-spacing: 0.08em;

  ${({ active }) =>
    active
      ? css`
          border: 0.2rem solid ${colorConfig.themeColor};
          color: ${colorConfig.themeColor};
          background-color: ${colorConfig.textSubColor};

          &::before {
            content: '';
            display: block;
            position: absolute;
            bottom: -1rem;
            width: 0;
            height: 0;
            border-style: solid;
            border-width: 1rem 1rem 0 1rem;
            border-color: ${colorConfig.themeColor} transparent transparent transparent;
          }

          &::after {
            content: '';
            display: block;
            position: absolute;
            bottom: -0.75rem;
            width: 0;
            height: 0;
            border-style: solid;
            border-width: 1rem 1rem 0 1rem;
            border-color: ${colorConfig.textSubColor} transparent transparent transparent;
          }
        `
      : css`
          color: ${colorConfig.textSubColor};
          background-color: ${colorConfig.themeColor};
        `}
`

const About = styled.section`
  padding-top: 8rem;
  padding-bottom: 8rem;

  ${breakpointUp('md')} {
    padding-top: 10rem;
    padding-bottom: 10rem;
  }
`

const AboutList = styled.ul`
  display: grid;
  gap: 2rem;
  margin-top: 4.8rem;

  ${breakpointUp('md')} {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 4rem 2.7rem;
    margin-top: 3.8rem;
  }
`

const Number = styled.div`
  padding: 8rem 0 10rem;
`

const NumberList = styled.ul`
  display: grid;
  margin-top: 4rem;
  gap: 1.6rem;

  ${breakpointUp('md')} {
    grid-template-columns: repeat(3, 1fr);
    gap: 2rem 2.8rem;
  }
`

const NumberCard = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  background-color: ${colorConfig.bgColor};
  padding: 2.4rem 0 2rem;
  font-weight: bold;

  ${breakpointUp('md')} {
    padding: 4rem 0;
    height: 21rem;
  }
`

const NumberHeading = styled.p`
  text-align: center;
`

const NumberAchievement = styled.div`
  color: ${colorConfig.themeColor};
  font-size: 2.8rem;

  ${breakpointDown('sm')} {
    /* margin-top: 0.8rem; */
  }

  ${breakpointUp('md')} {
    font-size: 4.8rem;
  }

  > small {
    font-size: 1.4rem;

    ${breakpointUp('md')} {
      font-size: 2.4rem;
    }
  }
`

const NumberNote = styled.div`
  text-align: right;
  font-size: 1.2rem;

  ${breakpointDown('sm')} {
    margin-top: 0.8rem;
  }

  ${breakpointUp('md')} {
    font-size: 1.4rem;
  }
`

const Flow = styled.section`
  margin-top: 8rem;
  margin-bottom: 10rem;

  ${breakpointUp('md')} {
    margin-top: 10rem;
    margin-bottom: 10rem;
  }
`

const FlowOrderedList = styled.ol`
  margin-top: 4rem;

  ${breakpointDown('sm')} {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 2.8rem 2.5rem;
  }

  ${breakpointUp('md')} {
    display: grid;
    grid-template-columns: repeat(6, 1fr);
    gap: 5.2rem;
  }

  > li {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;

    &::after {
      content: '';
      display: block;
      position: absolute;
      width: 2.1rem;
      height: 1.3rem;
      background: top / 100% no-repeat url(/images/service/icon_arrow-r.svg);
    }

    ${breakpointDown('sm')} {
      &:nth-of-type(odd) {
        &::after {
          top: 0;
          right: -2.2rem;
          bottom: 0;
          margin: auto 0;
        }
      }

      &:nth-of-type(even) {
        &::after {
          left: -2.4rem;
          bottom: -2.6rem;
          transform: rotate(135deg);
        }
      }
    }

    ${breakpointUp('md')} {
      &::after {
        top: -5rem;
        right: -3.2rem;
        bottom: 0;
        margin: auto 0;
      }
    }

    &:last-of-type {
      &::after {
        display: none;
      }
    }
  }
`

const FlowIcon = styled.div`
  width: 12.8rem;
`

const FlowLabel = styled.p`
  display: inline-flex;
  justify-content: center;
  align-items: center;
  position: relative;
  height: 100%;
  margin: -1.2rem -2rem 0;
  line-height: 1.5;
  font-size: 1.6rem;
  font-weight: bold;
  letter-spacing: 0.08em;
  color: ${colorConfig.themeColor};
  text-align: center;
`

const LinkGroup = styled.div`
  padding: 10rem 0;
`

const BannerList = styled.ul`
  display: flex;
  gap: 1.6rem;
  flex-wrap: wrap;
  justify-content: center;
  margin: 0 auto;

  > li {
    ${breakpointDown('sm')} {
      flex-shrink: 0;
      width: 100%;
    }

    ${breakpointUp('md')} {
      width: 33rem;
    }
  }

  a {
    ${mixins.hoverButton};
    display: block;
  }
`

const TextLinkContainer = styled.div`
  display: grid;
  gap: 1.6rem;
  margin-top: 3rem;
  text-align: center;
`

const Example = styled.div`
  margin-top: 8rem;
`

const ExampleHeading = styled.div`
  margin-top: 8rem;
  margin-bottom: 4rem;
`

const LinkButtonContainer = styled.div`
  margin-top: 10rem;
  padding: 10rem 0;
  text-align: center;

  ${breakpointUp('md')} {
    margin-top: 8rem;
    padding: 8rem 0;
  }
`

export default connect(mapStateToProps, mapDispatchToProps)(IndexPage)
